import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import {
  AppAside,
  // AppBreadcrumb,
  // AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
// import DefaultFooter from './DefaultFooter';
import DefaultHeader from "./DefaultHeader";
import auth from "../../api/auth/Auth.jsx";
import LoginOutcomex from "../../views/Login/Login";
import NotificationSystem from "react-notification-system";

import { style } from "../../variables/Variables.jsx";
import { ability } from "../../components/AccessControl/Can";
import {
  MICROSOFT_SOCIAL_LOGIN_CLIENDT_ID,
  MICROSOFT_SOCIAL_LOGIN_TENANT_URL
} from "../../api/util/configurationConstants.jsx";
import { UserAgentApplication } from "msal";
import services from "../../api/Services/Services.jsx";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAuth = this.handleChangeAuth.bind(this);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);

    // this.state = {
    //   /**
    //    * Whether the current user is authenticated or not.
    //    * @type {boolean}
    //    */
    //   isAuth: auth.isAuthenticated()
    // };
    this.isAuth = auth.isAuthenticated();
  }

  handleNotificationClick(position, msg, iconName, mLevel, nid, dismissTime) {
    if (!this.refs.notificationSystem) {
      return;
    }
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    level = !mLevel ? level : mLevel;
    let notificationMessage;
    if (!msg) {
      return;
    } else {
      notificationMessage = msg;
    }
    /**
     * Avoids duplicate notifications
     */
    var found = null;
    if (nid !== undefined) {
      found = this.refs.notificationSystem.state.notifications.find(function(
        element
      ) {
        return element.nid === nid;
      });
    }

    if (found === undefined || found === null) {
      this.refs.notificationSystem.addNotification({
        title: (
          <span
            data-notify="icon"
            className={iconName ? "pe-7s-info" : "pe-7s-map-marker"}
          />
        ),
        message: notificationMessage,
        level: level,
        position: position,
        autoDismiss: dismissTime === undefined || dismissTime === null ? 15 : 0,
        nid: nid
      });
    }
  }

  /**
   * onTimeout periodically POSTs to the backend server that the user is online
   */
  onTimeout = () => {
    // only posts if the user is authenticated
    if (this.isAuth === true) {
      this.postOnlineUsers();
    }
  };

  /**
   * Changes the customer side appearance depending on the user being authenticated
   * or not.
   *
   * @param {boolean} isAuth whether the current user is authenticated or not
   */
  handleChangeAuth(isAuth) {
    this.isAuth = isAuth;
    if (this.isAuth) {
      this.postOnlineUsers();
    }
    this.forceUpdate();
  }

  componentDidMount() {
    this.timer = setInterval(this.onTimeout, 10000);
    this.handleChangeAuth(auth.isAuthenticated());
    auth.onChange = this.handleChangeAuth;
    if (this.isAuth) {
      this.postOnlineUsers();
    }
  }

  /**
   * postOnlineUsers sends a POST to the server to inform that the user is still online
   */
  postOnlineUsers = () => {
    services.postOnlineUsers((result, data) => {});
  };

  /**
   * Unregisters this component's authentication change listener with the
   * auth object.
   */
  componentWillUnmount() {
    auth.onChange = null;
  }
  render() {
    let windowLocationHash = window.location.hash;
    // the following line of code is due to the microsoft's authentication method
    // given the issue(s):
    //   https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
    //   https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/545
    // the main page needs to check if the response from the azure authentication, contains the token and, if so,
    // it needs to create a new UserAgentApplication to close the modal
    if (
      windowLocationHash.includes("#/id_token") ||
      windowLocationHash.includes("#/access_token")
    ) {
      new UserAgentApplication(
        MICROSOFT_SOCIAL_LOGIN_CLIENDT_ID,
        MICROSOFT_SOCIAL_LOGIN_TENANT_URL,
        null
      );
    }
    let isAuth = this.isAuth;
    if (!isAuth) {
      return (
        <div id="main-panel" className="main-panel-login">
          <Switch>
            <Route path="/login" name="Login Page" component={LoginOutcomex} />
            <Redirect to="/login" />
          </Switch>
        </div>
      );
    }

    let tmpNavigation = JSON.parse(JSON.stringify(navigation));
    tmpNavigation.items = tmpNavigation.items.filter(function(element) {
      // the route is visible to all
      if (element.checkAbility === false) {
        return true;
      }
      return ability.can("see", element.name);
    });

    let defaultRedictTo = "/mam";
    if (!ability.can("see", "MAM")) {
      defaultRedictTo = "/crm";
    }

    let newUrl = "";
    let pageUrl = this.props.location.pathname;
    if (pageUrl === "/mam" || pageUrl === "/management/commission_structure") {
      newUrl = "app-body-mam";
    } else {
      newUrl = "";
    }

    return (
      <div className="app">
        <NotificationSystem ref="notificationSystem" style={style} />
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className={"app-body " + newUrl}>
          {/* {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
          <div className="watermark">
            <p className="watermark-text">DEVELOPMENT</p>
          </div>
        } */}
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={tmpNavigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes}/> */}
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    route.checkAbility ? (
                      ability.can("see", route.name) ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                            <route.component
                              {...props}
                              handleClick={this.handleNotificationClick}
                            />
                          )}
                        />
                      ) : null
                    ) : (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component
                            {...props}
                            handleClick={this.handleNotificationClick}
                          />
                        )}
                      />
                    )
                  ) : null;
                })}
                <Redirect from="/" to={defaultRedictTo} />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        {/* <AppFooter>
        <DefaultFooter />
      </AppFooter> */}
      </div>
    );
  }
}

export default DefaultLayout;
