/**
 * Abstracts the authentication functionalities.
 */
import axios from "axios";
import jwtDecode from "jwt-decode";
import { ability } from "../../components/AccessControl/Can";
import defineRulesFor from "../../config/ability";
/**
 * Saves the JWT token data to the browser's local storage for later use, if
 * not expired.
 *
 * This function also calls _populateToken for convenience.
 *
 * @param {object} data Object with attributes 'access' and 'refresh', where each is the
 * base64 representation of the respective JWT tokens.
 */
function _saveToken(data, profileImgUrl) {
  let tmpAuthorization = JSON.parse(data.headers.authorization);
  _populateToken(tmpAuthorization, profileImgUrl);

  window.localStorage.setItem(
    "oxmam-jwt-token",
    JSON.stringify({
      access: tmpAuthorization.access,
      refresh: tmpAuthorization.refresh,
      profileImgUrl: profileImgUrl
    })
  );
}

/**
 * Converts the data returned from API calls into attributes in the auth
 * object.
 *
 * @param {Object} data Object with attributes 'access' and 'refresh', where each is the
 * base64 representation of the respective JWT tokens.
 */
function _populateToken(data, profileImgUrl) {
  auth.accessToken = {
    raw: data.access,
    decoded: jwtDecode(data.access)
  };
  auth.refreshToken = {
    raw: data.refresh,
    decoded: jwtDecode(data.refresh)
  };
  auth.profileImgUrl = profileImgUrl;
}

/**
 * This singleton object makes all the authentication services available to the
 * frontend application.
 */
export var auth = {
  /**
   * Retrieved JWT access token. Contains two attributes: raw and decoded, both string.
   * The raw attribute is the JWT token as string, and decoded as an object.
   * @type {object}
   */
  accessToken: null,

  /**
   * Retrieved JWT refresh token. Contains two attributes: raw and decoded, both string.
   * The raw attribute is the JWT token as string, and decoded as an object.
   * @type {object}
   */
  refreshToken: null,

  publicProfile: null,

  /**
   * Listener function called when there are changes to the authentication state, e.g. user
   * went from authenticated to not authenticated.
   * @type {function(boolean)}
   */
  onChange: null,

  /**
   * Used internally to queue all operations still pending after discovered that the current
   * JWT access token has expired. The pending operations will be completed after a new JWT
   * access token is received.
   * @type {function[]}
   */
  refreshCallbackQueue: [],

  /**
   * Initialises the authentication state and updates the auth object.
   *
   * This should be called once, before the auth singleton is used.
   *
   * It will verify if the local storage contains a previously stored JWT token
   * and restore its values to the right attributes. Also, if no token has been
   * found but a hint is given that a sessionid Cookie is present, it'll try to
   * obtain a JWT token by authenticating using the Cookie.
   *
   */
  initialise: function() {
    let jwtToken = window.localStorage.getItem("oxmam-jwt-token");

    if (jwtToken) {
      jwtToken = JSON.parse(jwtToken);

      this.accessToken = {
        raw: jwtToken.access,
        decoded: jwtDecode(jwtToken.access)
      };

      this.refreshToken = {
        raw: jwtToken.refresh,
        decoded: jwtDecode(jwtToken.refresh)
      };

      this.profileImgUrl = jwtToken.profileImgUrl;
      ability.update(defineRulesFor(this.accessToken.decoded));
    }
  },

  /**
   * Returns true if the JWT access token is expired. If true, accessToken attribute
   * will be nullified.
   *
   * @returns {boolean}
   */
  isAccessExpired: function() {
    if (this.accessToken && this.accessToken.decoded.exp) {
      let exp = 1000 * this.accessToken.decoded.exp - new Date().getTime() < 5000;

      if (exp) {
        this.accessToken = null;
      }

      return exp;
    }

    return true;
  },

  /**
   * Returns true if the JWT refresh token is expired. If true, accessToken and
   * refreshToken attributes will be nullified and the token will be removed
   * from local storage. The user will be required to log in again.
   *
   * @returns {boolean}
   */
  isRefreshExpired: function() {
    if (this.refreshToken && this.refreshToken.decoded.exp) {
      let exp = 1000 * this.refreshToken.decoded.exp - new Date().getTime() < 5000;
      if (exp) {
        this.logout();
      }

      return exp;
    }

    return true;
  },

  /**
   * Returns true if the current tokens are still valid or refreshable.
   *
   * @returns {boolean}
   */
  isAuthenticated: function() {
    return !this.isRefreshExpired();
  },

  /**
   * Attempts to authenticate with the backend and obtain a JWT token.
   *
   * This method will call the onChange attribute callback to notify the interested parties
   * of a change in authentication state.
   *
   * @param {string} username
   * @param {string} password
   * @param {function(boolean,object)} callback Will be called when the authentication result
   * is available. The first argument is true if the authentication succeeded; the second argument
   * provides the error response otherwise.
   */
  login: function(tokenid, profileImgUrl, callback) {
    let self = this;

    axios
      .post("/auth", {
        tokenid: tokenid
      })
      .then(function(response) {
        _saveToken(response, profileImgUrl);
        // the abilities need to be updated before calling the onChange
        ability.update(defineRulesFor(self.accessToken.decoded));
        if (self.onChange) {
          self.onChange(self.isAuthenticated());
        }

        if (callback) {
          callback(true);
        }
      })
      .catch(function(error) {
        if (self.onChange) {
          self.onChange(self.isAuthenticated());
        }

        if (callback) {
          callback(false, error.response);
        }
      });
  },
  /**
   * Logs the current user out, removing the stored JWT token and reloading the page
   * completely.
   *
   * Additionally, it the currently logged in user is a staff user (read only), will
   * log the user out of the admin pages as well to remove the sessionid Cookie.
   */
  logout: function() {
    window.localStorage.removeItem("oxmam-jwt-token");
    this.refreshToken = null;
    this.accessToken = null;
    ability.update(defineRulesFor([]));
    window.location.reload();
  },

  /**
   * Attempts to refresh the JWT access token using the stored refresh token.
   *
   * The process will only happen if the a refresh operation is not running at the
   * moment and if the current refresh token is not expired. Callback functions can
   * be queued to be called back when the process is completed.
   *
   * @param {function(boolean,object)} callback Will be called when the refresh result
   * is available. The first argument is true if the refresh succeeded; the second argument
   * provides the error response otherwise.
   */
  refresh: function(callback) {
    if (!this.isRefreshExpired() || this.isRefreshing) {
      let self = this;
      this.refreshCallbackQueue.push(callback);

      if (this.isRefreshing === false || this.isRefreshing === undefined) {
        this.isRefreshing = true;
        axios
          .post("/api/v1/refresh/", {
            refresh: this.refreshToken.raw
          })
          .then(function(response) {
            _saveToken(response, self.profileImgUrl);
            // the abilities need to be updated before calling the onChange
            ability.update(defineRulesFor(self.accessToken.decoded));

            if (self.onChange) {
              self.onChange(self.isAuthenticated());
            }

            while (self.refreshCallbackQueue.length > 0) {
              self.refreshCallbackQueue.pop()(true);
            }
            self.isRefreshing = false;
          })
          .catch(function(error) {
            if (self.onChange) {
              self.onChange(self.isAuthenticated());
            }

            while (self.refreshCallbackQueue.length > 0) {
              self.refreshCallbackQueue.pop()(false, error.response);
            }
            self.isRefreshing = false;
          });
      }
    }
  },

  /**
   * Checks whether a token refresh is required and act accordingly.
   *
   * Returns true if the access token is immediately available. Otherwise,
   * schedules a token refresh, returns false, and queues callback to be called
   * when the refresh process is completed.
   *
   * The callback function will not be called it the access token is not expired.
   *
   * @param {function(boolean,object)} callback Will be called when the refresh result
   * is available. The first argument is true if the refresh succeeded; the second argument
   * provides the error response otherwise.
   */
  checkBeforeUse: function(callback) {
    // Is the access token still fresh?
    if (!this.isAccessExpired()) {
      return true;
    }
    // Then, we'll need to renew it.
    // Check if we can refresh, this will reload the page in case we can't.
    if (!this.isRefreshExpired()) {
      this.refresh(callback);
      return false;
    }
  }
};

export default auth;
