import React from "react";
import auth from "../../api/auth/Auth.jsx";
import { Container } from "reactstrap";

import logo from "../../assets/img/brand/logo2.png";
import MyBackgroundImage from "../../assets/img/sidebar-6.jpg";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";
import GoogleLogin from "react-google-login";
import * as ConfigurationConstants from "../../api/util/configurationConstants.jsx";
import MicrosoftLogin from "../../components/MicrosoftLogin/MicrosoftLogin";

/**
 * View displayed while the user is not authenticated against the backend
 *
 * It allows the user to log in using social login provided by Google and Microsoft
 */
class LoginOutcomex extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * Display status messages for the user.
       */
      _notificationSystem: null,
      /**
       * True if the current user is authenticated with the backend, false otherwise
       * @type {boolean}
       */
      isAuth: auth.isAuthenticated()
    };
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystemLogin });
  }

  /**
   * Handles the result of a submit action. This will only make changes
   * to the UI if an error ocurred, as otherwise the whole Login component
   * is unmounted.  *
   * @param {boolean} result
   * @param {object} response
   * @public
   */
  handleLoginResult = (result, response) => {
    if (!result) {
      if (response) {
        if (response.status === 500) {
          this.showNotification("Error while trying to connect with the server. \nTry again later.");
        } else if (response.status === 401) {
          this.showNotification("Access denied. Check your credentials.");
          // this.signOut();
        } else {
          this.showNotification("An error occurred. Please try again later.");
        }
      } else {
        this.showNotification("An error occurred. Please try again later.");
      }

      this.setState({
        isAuth: false
      });
    }
  };

  /**
   * Updates the auth object with the token received from the backend
   *
   * @param {response} a GoogleAuth object
   * @public
   */
  googleLoginOnSuccess(response) {
    auth.login(response.tokenObj.id_token, response.profileObj.imageUrl, this.handleLoginResult);
  }

  /**
   * Called when the google authentication process failed
   *
   * @param {response} an object containing an error property, if GoogleAuth failed to initialize.
   * @public
   */
  googleLoginOnFailure(response) {
    if (response.error !== undefined) {
      console.info(response.error);
      // ignore pop close action
    } else if (response.type !== undefined) {
      // route to error page
    }
  }

  /**
   * Displays a message on the screen
   *
   * @param {msg} a string representing the desired message to be displayed
   * @public
   */
  showNotification(msg) {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={"pe-7s-attention"} />,
      message: msg,
      level: "error",
      position: "tc",
      autoDismiss: 10
    });
  }

  /**
   * Callback to the microsoft login response
   *
   * In case of success, the application will try to authenticate with the backend, retreiving a JWT token
   *
   * @param {err} the error message in case of error, null otherwise
   * @param {data} data, in case of success, contains the id token to be validate at the backend
   * @public
   */
  handleMicrosoftLogin = (err, data) => {
    if (!err) {
      auth.login(data.accessToken, "", this.handleLoginResult);
    } else {
      console.error(err);
    }
  };

  render() {
    return (
      <div style={{ backgroundImage: `url(${MyBackgroundImage})` }} className="content app flex-row align-items-center">
        <Container>
          <div>
            {" "}
            <NotificationSystem ref="notificationSystemLogin" style={style} />
            <div>
              <img width="256" height="40" src={logo} className="attachment-full size-full" alt="" />
              <br />
              <br />
              <GoogleLogin
                className="google-login-button"
                clientId={ConfigurationConstants.GOOGLE_SOCIAL_LOGIN_API_KEY}
                fetchBasicProfile={true}
                scope=""
                onSuccess={this.googleLoginOnSuccess.bind(this)}
                onFailure={this.googleLoginOnFailure.bind(this)}
                offline={false}>
                <span> Sign in with Google</span>
              </GoogleLogin>
              <div style={{ marginTop: "10px" }} />
              <MicrosoftLogin
                clientId={ConfigurationConstants.MICROSOFT_SOCIAL_LOGIN_CLIENDT_ID}
                authCallback={this.handleMicrosoftLogin}
                tenantUrl={ConfigurationConstants.MICROSOFT_SOCIAL_LOGIN_TENANT_URL}
                graphScopes={["user.read"]} // the permissions required
                debug={false} // show debug messages on the console
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default LoginOutcomex;
