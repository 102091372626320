export default {
  items: [
    {
      name: "MAM",
      url: "/mam",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Negative Gross Profit",
      url: "/negative_gross_profit",
      icon: "pe-7s-note2"
    },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "pe-7s-news-paper"
    },
    {
      name: "NSA Dashboard",
      url: "/nsa_dashboard",
      icon: "pe-7s-news-paper"
    },
    {
      name: "CRM",
      url: "/crm",
      icon: "pe-7s-news-paper",
      checkAbility: false
    },
    {
      name: "Open Quotes",
      url: "/account_manager/open_quotes",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Non Quoted Opportunities",
      url: "/account_manager/non_quoted_opportunities",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Quotes Lost",
      url: "/account_manager/quotes_lost",
      icon: "pe-7s-news-paper"
    },

    {
      name: "Customer Opportunity Heatmap",
      url: "/opportunities_per_customer",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Summary of Performance",
      url: "/account_manager/summary_revenue_performance",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Commission Summary",
      url: "/account_manager/commission_achieved",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Vendor Deal-ID Discounts",
      url: "/deal_id_discount",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Deal ID Registration Process",
      url: "/deal_id_registration",
      icon: "pe-7s-news-paper"
    },
    {
      name: "Tender Logins",
      url: "/account_manager/tender_logins",
      icon: "pe-7s-news-paper",
      checkAbility: false
    },
    {
      name: "Vendors/Equipment Makers",
      url: "/find_vendor_acc_mngr",
      icon: "pe-7s-news-paper",
      checkAbility: false
    },
    {
      name: "Users Management",
      url: "/management/users",
      icon: "pe-7s-users"
    },
    {
      name: "Commission Structure",
      url: "/management/commission_structure",
      icon: "pe-7s-note2"
    },
    {
      name: "Changes History",
      url: "/changes/history",
      icon: "pe-7s-note2"
    },
    {
      name: "Suppliers/Distributors",
      url: "/suppliers",
      icon: "pe-7s-note2",
      checkAbility: false
    },
    // {
    //   name: "Vendors",
    //   url: "/vendors",
    //   icon: "pe-7s-note2"
    // },
    {
      name: "Customer Financial Summary",
      url: "/customer_profitability",
      icon: "pe-7s-note2"
    },
    {
      name: "Engineer Profitability",
      url: "/engineer_profitability",
      icon: "pe-7s-note2"
    },
    {
      name: "Account Manager Profitability",
      url: "/account_manager_profitability",
      icon: "pe-7s-note2"
    },
    {
      name: "Annuity Renewals",
      url: "/annuity_renewals",
      icon: "pe-7s-note2"
    },

    {
      name: "Fiscal Year",
      url: "/fiscal_year",
      icon: "pe-7s-note2"
    },

    {
      name: "Create Quote",
      url: "/quotes",
      icon: "pe-7s-note2"
    },

    {
      name: "Renewals",
      url: "/renewals",
      icon: "pe-7s-note2"
    },
    {
      name: "Expenses",
      url: "/expenses",
      icon: "pe-7s-note2"
    },
    {
      name: "Expensesv2",
      url: "/expensesv2",
      icon: "pe-7s-note2"
    },
    {
      name: "Invoices",
      url: "/invoices",
      icon: "pe-7s-note2"
    },
    {
      name: "Purchase Orders",
      url: "/purchaseorder",
      icon: "pe-7s-note2"
    },
    {
      name: "Users Activity",
      url: "/userLog",
      icon: "pe-7s-note2" 
    }


    // {
    //   name: 'Reports',
    //   url: '/reports',
    //   icon: 'pe-7s-note2',
    //   children: [
    //     {
    //       name: 'Total Won',
    //       url: '/reports/total_won',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Total Open Quotes',
    //       url: '/reports/total_open_quotes',
    //       icon: 'icon-puzzle',
    //     },
    //   ]
    // },
    // {
    //   title: true,
    //   name: 'Components',
    //   wrapper: {
    //     element: '',
    //     attributes: {},
    //   },
    // },

    // {
    //   name: 'Base',
    //   url: '/base',
    //   icon: 'icon-puzzle',
    //   children: [
    //     {
    //       name: 'Breadcrumbs',
    //       url: '/base/breadcrumbs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Cards',
    //       url: '/base/cards',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Carousels',
    //       url: '/base/carousels',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Collapses',
    //       url: '/base/collapses',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Dropdowns',
    //       url: '/base/dropdowns',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Forms',
    //       url: '/base/forms',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Jumbotrons',
    //       url: '/base/jumbotrons',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'List groups',
    //       url: '/base/list-groups',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Navs',
    //       url: '/base/navs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Paginations',
    //       url: '/base/paginations',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Popovers',
    //       url: '/base/popovers',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Progress Bar',
    //       url: '/base/progress-bar',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Switches',
    //       url: '/base/switches',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tables',
    //       url: '/base/tables',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tabs',
    //       url: '/base/tabs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tooltips',
    //       url: '/base/tooltips',
    //       icon: 'icon-puzzle',
    //     },
    //   ],
    // },
    // {
    //   name: 'Buttons',
    //   url: '/buttons',
    //   icon: 'icon-cursor',
    //   children: [
    //     {
    //       name: 'Buttons',
    //       url: '/buttons/buttons',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Button dropdowns',
    //       url: '/buttons/button-dropdowns',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Button groups',
    //       url: '/buttons/button-groups',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Brand Buttons',
    //       url: '/buttons/brand-buttons',
    //       icon: 'icon-cursor',
    //     },
    //   ],
    // },
    // {
    //   name: 'Charts',
    //   url: '/charts',
    //   icon: 'icon-pie-chart',
    // },
    // {
    //   name: 'Icons',
    //   url: '/icons',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'CoreUI Icons',
    //       url: '/icons/coreui-icons',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'info',
    //         text: 'NEW',
    //       },
    //     },
    //     {
    //       name: 'Flags',
    //       url: '/icons/flags',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Font Awesome',
    //       url: '/icons/font-awesome',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'secondary',
    //         text: '4.7',
    //       },
    //     },
    //     {
    //       name: 'Simple Line Icons',
    //       url: '/icons/simple-line-icons',
    //       icon: 'icon-star',
    //     },
    //   ],
    // },
    // {
    //   name: 'Notifications',
    //   url: '/notifications',
    //   icon: 'icon-bell',
    //   children: [
    //     {
    //       name: 'Alerts',
    //       url: '/notifications/alerts',
    //       icon: 'icon-bell',
    //     },
    //     {
    //       name: 'Badges',
    //       url: '/notifications/badges',
    //       icon: 'icon-bell',
    //     },
    //     {
    //       name: 'Modals',
    //       url: '/notifications/modals',
    //       icon: 'icon-bell',
    //     },
    //   ],
    // },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: true,
    //   name: 'Extras',
    // },
    // {
    //   name: 'Pages',
    //   url: '/pages',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/login',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Register',
    //       url: '/register',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Error 404',
    //       url: '/404',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Error 500',
    //       url: '/500',
    //       icon: 'icon-star',
    //     },
    //   ],
    // },
    // {
    //   name: 'Download CoreUI',
    //   url: 'http://coreui.io/react/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Try CoreUI PRO',
    //   url: 'http://coreui.io/pro/react/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    // },
  ]
};
