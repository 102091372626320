import React, { Component } from "react";
import { ListGroupItem } from "reactstrap";

class OnlineUsers extends Component {
  render() {
    return (
      <React.Fragment>
        <ListGroupItem className="list-group-item-accent-light bg-light text-center font-weight-bold text-muted text-uppercase small">
          Online (Last Minute)
        </ListGroupItem>

        {this.props.data.map((element, index) => {
          return (
            <ListGroupItem
              key={index}
              className="list-group-item-accent-primary list-group-item-divider"
            >
              <div>
                <strong>{element.name}</strong>{" "}
              </div>

              {/* {element.lastAccess ? (
                <div>
                  <i className="fa fa-clock-o"></i>&nbsp; {moment(element.lastAccess).fromNow()}
                </div>
              ) : (
                <div />
              )} */}
            </ListGroupItem>
          );
        })}
      </React.Fragment>
    );
  }
}

export default OnlineUsers;
