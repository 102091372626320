import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
import './assets/css/pe-icon-7-stroke.css';
import 'rc-datepicker/lib/style.css';
import 'moment/locale/en-au.js'
import "react-select/dist/react-select.css";
import 'react-table/react-table.css'
import 'react-virtualized/styles.css'

import auth from "./api/auth/Auth.jsx"

auth.initialise();

ReactDOM.render(<App />, document.getElementById('root'));



