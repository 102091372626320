import React, { Component } from "react";
import { Input } from "reactstrap";

class InputFilter extends Component {
    render() {
        return (
            <Input
                type="text"
                placeholder={this.props.placeholder ? this.props.placeholder : ""}
                onChange={event => {
                    this.props.onChange(this.props.columnName, event);
                }}
            />
        );
    }
}

export default InputFilter;