import React from "react";
import Loadable from "react-loadable";
import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard/Dashboard.jsx"),
  loading: Loading
});

const MAM = Loadable({
  loader: () => import("./views/MAM/MAM.jsx"),
  loading: Loading
});

const UsersManagement = Loadable({
  loader: () => import("./views/UsersManagement/UsersManagement.jsx"),
  loading: Loading
});

const CommissionStructure = Loadable({
  loader: () => import("./views/CommissionStructure/CommissionStructure.jsx"),
  loading: Loading
});

const ChangesHistory = Loadable({
  loader: () => import("./views/ChangesHistory/ChangesHistory.jsx"),
  loading: Loading
});

const CRM = Loadable({
  loader: () => import("./views/CRM/CRM.jsx"),
  loading: Loading
});

const NonQuotedOpportunities = Loadable({
  loader: () => import("./views/AccountManagerForecasting/NonQuotedOpportunities"),
  loading: Loading
});

const QuotesLost = Loadable({
  loader: () => import("./views/AccountManagerForecasting/QuotesLost"),
  loading: Loading
});

const TenderLogins = Loadable({
  loader: () => import("./views/TenderLogins/TenderLogins.jsx"),
  loading: Loading
});

const OpenQuotes = Loadable({
  loader: () => import("./views/AccountManagerForecasting/OpenQuotes"),
  loading: Loading
});

const OpportunitiesPerCustomerSalesPerformance = Loadable({
  loader: () => import("./views/OpportunitiesPerCustomerSalesPerformance/OpportunitiesPerCustomerSalesPerformance"),
  loading: Loading
});

const SummaryOfRevenueAndPerformance = Loadable({
  loader: () => import("./views/AccountManager/SummaryOfRevenueAndPerformance"),
  loading: Loading
});

const CommissionAchieved = Loadable({
  loader: () => import("./views/AccountManager/CommissionAchieved"),
  loading: Loading
});

const NegativeGroupedGrossProfit = Loadable({
  loader: () => import("./views/NegativeGroupedGrossProfit/NegativeGroupedGrossProfit"),
  loading: Loading
});

const DealIDRegistrationProcess = Loadable({
  loader: () => import("./views/DealIDRegistrationProcess/DealIDRegistrationProcess"),
  loading: Loading
});

const FindVendorAccountManager = Loadable({
  loader: () => import("./views/FindVendorAccountManager/FindVendorAccountManager"),
  loading: Loading
});

const DealIDDiscount = Loadable({
  loader: () => import("./views/DealIDDiscount/DealIDDiscount"),
  loading: Loading
});

const Suppliers = Loadable({
  loader: () => import("./views/Suppliers/Suppliers"),
  loading: Loading
});

// const Vendors = Loadable({
//   loader: () => import("./views/Vendors/Vendors"),
//   loading: Loading
// });

const CustomerProfitability = Loadable({
  loader: () => import("./views/Reports/CustomerProfitability"),
  loading: Loading
});

const EngineerProfitability = Loadable({
  loader: () => import("./views/Reports/EngineerProfitability"),
  loading: Loading
});

const AccountManagerProfitability = Loadable({
  loader: () => import("./views/Reports/AccountManagerProfitability"),
  loading: Loading
});

const AnnuityRenewals = Loadable({
  loader: () => import("./views/AnnuityRenewals/AnnuityRenewals"),
  loading: Loading
});

const FiscalYear = Loadable({
  loader: () => import("./views/FiscalYear/FiscalYear"),
  loading: Loading
});

const NsaDashboard = Loadable({
  loader: () => import("./views/Dashboard/NsaDashboard"),
  loading: Loading
});

const ListQuotes = Loadable({
  loader: () => import("./views/QuoteModule/ListQuotes"),
  loading: Loading
});

const RenewalsMainPage = Loadable({
  loader: () => import("./views/RenewalsModule/RenewalsMainPage"),
  loading: Loading
});

const ExpensesMainPage = Loadable({
  loader: () => import("./views/ExpensesModule/ExpensesMainPage"),
  loading: Loading
});

const ExpensesMainPagev2 = Loadable({
  loader: () => import("./views/ExpensesModulev2/ExpensesMainPagev2"),
  loading: Loading
});

const InvoicesMainPage = Loadable({
  loader: () => import("./views/InvoicesModule/InvoicesMainPage"),
  loading: Loading
});

const PurchaseOrdersMainPage = Loadable({
  loader: () => import("./views/PurchaseOrdersModule/PurchaseOrdersMainPage"),
  loading: Loading
});

const UserLog = Loadable({
  loader: () => import("./views/UserLog/UserLog"),
  loading: Loading  
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Home", component: DefaultLayout },
  { path: "/dashboard", name: "Dashboard", component: Dashboard, checkAbility: true },
  { path: "/nsa_dashboard", name: "NSA Dashboard", component: NsaDashboard, checkAbility: true },
  { path: "/negative_gross_profit", name: "Negative Gross Profit", component: NegativeGroupedGrossProfit, checkAbility: true },
  { path: "/mam", name: "MAM", component: MAM, checkAbility: true },
  { path: "/crm/:clientId?", name: "CRM", component: CRM, checkAbility: false },
 
  {
    path: "/deal_id_discount",
    name: "Vendor Deal-ID Discounts",
    component: DealIDDiscount,
    checkAbility: false
  },
  {
    path: "/deal_id_registration",
    name: "Deal ID Registration Process",
    component: DealIDRegistrationProcess,
    checkAbility: true
  },
  {
    path: "/find_vendor_acc_mngr",
    name: "Vendors/Equipment Makers",
    component: FindVendorAccountManager,
    checkAbility: false
  },
  { path: "/crm", name: "CRM", component: CRM, checkAbility: true },
  {
    path: "/management/users",
    name: "Users Management",
    component: UsersManagement,
    checkAbility: true
  },
  {
    path: "/management/commission_structure",
    name: "Commission Structure",
    component: CommissionStructure,
    checkAbility: true
  },
  {
    path: "/changes/history",
    name: "Changes History",
    component: ChangesHistory,
    checkAbility: true
  },
  {
    path: "/account_manager/non_quoted_opportunities",
    name: "Non Quoted Opportunities",
    component: NonQuotedOpportunities,
    checkAbility: true
  },
  {
    path: "/account_manager/quotes_lost",
    name: "Quotes Lost",
    component: QuotesLost,
    checkAbility: true
  },
  {
    path: "/account_manager/open_quotes",
    name: "Open Quotes",
    component: OpenQuotes,
    checkAbility: true
  },
  {
    path: "/opportunities_per_customer",
    name: "Customer Opportunity Heatmap",
    component: OpportunitiesPerCustomerSalesPerformance,
    checkAbility: true
  },
  {
    path: "/account_manager/summary_revenue_performance",
    name: "Summary of Performance",
    component: SummaryOfRevenueAndPerformance,
    checkAbility: true
  },
  {
    path: "/account_manager/commission_achieved",
    name: "Commission Summary",
    component: CommissionAchieved,
    checkAbility: true
  },
  {
    path: "/account_manager/tender_logins",
    name: "Tender Logins",
    component: TenderLogins,
    checkAbility: false
  },
  {
    path: "/suppliers",
    name: "Suppliers/Distributors",
    component: Suppliers,
    checkAbility: false
  },
  // {
  //   path: "/vendors",
  //   name: "Vendors",
  //   component: Vendors,
  //   checkAbility: true
  // },
  {
    path: "/customer_profitability",
    name: "Customer Financial Summary",
    component: CustomerProfitability,
    checkAbility: true
  },
  {
    path: "/engineer_profitability",
    name: "Engineer Profitability",
    component: EngineerProfitability,
    checkAbility: true
  },
  {
    path: "/account_manager_profitability",
    name: "Account Manager Profitability",
    component: AccountManagerProfitability,
    checkAbility: true
  },
  {
    path: "/annuity_renewals",
    name: "Annuity Renewals",
    component: AnnuityRenewals,
    checkAbility: true
  },
  {
    path: "/fiscal_year",
    name: "Fiscal Year",
    component: FiscalYear,
    checkAbility: true
  },
  {
    path: "/quotes",
    name: "Create Quote",
    component: ListQuotes,
    checkAbility: true
  },
  {
    path: "/renewals",
    name: "Renewals",
    component: RenewalsMainPage,
    checkAbility: true
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: ExpensesMainPage,
    checkAbility: true
  },
  {
    path: "/expensesv2",
    name: "Expensesv2",
    component: ExpensesMainPagev2,
    checkAbility: true
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: InvoicesMainPage,
    checkAbility: true
  },
  {
    path: "/purchaseorder",
    name: "Purchase Orders",
    component: PurchaseOrdersMainPage,
    checkAbility: true
  },
  { 
    path: "/userLog",
    name: "Users Activity",
    component: UserLog,
    checkAbility: true
  }
];

export default routes;
