import React, { Component } from "react";
import { UserAgentApplication } from "msal";
import MicrosoftLoginButton from "./MicrosoftLoginButton ";
const CLIENT_ID_REGEX = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/;

export default class MicrosoftLogin extends Component {
  constructor(props) {
    super(props);
    const { debug, graphScopes } = props;
    const scope = graphScopes || [];
    scope.some(el => el.toLowerCase() === "user.read") || scope.push("user.read");
    this.state = {
      msalInstance:
        props.clientId &&
        CLIENT_ID_REGEX.test(props.clientId) &&
        new UserAgentApplication(props.clientId, props.tenantUrl || null, () => {}, {
          navigateToLoginRequestUrl: false
        }),
      scope: scope,
      debug: debug || false
    };
  }

  componentDidMount() {
    const { msalInstance } = this.state;
    if (!msalInstance) {
      this.log("Initialization", "clientID broken or not provided", true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { clientId, tenantUrl } = this.props;
    if (prevProps.clientId !== clientId || prevProps.tenantUrl !== tenantUrl) {
      this.setState({
        msalInstance:
          clientId &&
          CLIENT_ID_REGEX.test(clientId) &&
          new UserAgentApplication(clientId, tenantUrl || null, () => {}, {
            navigateToLoginRequestUrl: false
          })
      });
    }
  }

  login() {
    const { msalInstance, scope, debug } = this.state;
    const { authCallback } = this.props;

    if (msalInstance) {
      debug && this.log("Login STARTED", true);
      this.popupLogin(msalInstance, scope, authCallback, debug);
    } else {
      this.log("Login FAILED", "clientID broken or not provided", true);
    }
  }

  popupLogin(msalInstance, scope, authCallback, debug) {
    debug && this.log("Fetch Azure AD 'token' with popup STARTED", true);
    msalInstance
      .loginPopup(scope)
      .then(idToken => {
        debug && this.log("Fetch Azure AD 'token' with popup SUCCEDEED", idToken);
        authCallback(null, { accessToken: idToken });
      })
      .catch(error => {
        debug && this.log("Fetch Azure AD 'token' with popup FAILED", error, true);
        authCallback(error, null);
      });
  }

  log(name, content, isError) {
    const style = `background-color: ${
      isError ? "#990000" : "#009900"
    }; color: #ffffff; font-weight: 700; padding: 2px`;
    console.groupCollapsed("MSLogin debug");
    console.log(`%c${name}`, style);
    console.log(content);
    console.groupEnd();
  }

  render() {
    const { buttonTheme, className } = this.props;
    return (
      <div>
        <MicrosoftLoginButton
          buttonTheme={buttonTheme || "light"}
          buttonClassName={className}
          onClick={this.login.bind(this)}
        />
      </div>
    );
  }
}
