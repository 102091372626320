/**
 * Implementation of all API calls to the backend. The services object is exported
 * for use in the other modules.
 *
 * For additional information on the fields required and return by the calls, check
 * documentation related to backend's views and serialisers.
 */
import axios from "axios";
import auth from "../auth/Auth.jsx";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
/**
 * Verifies authentication state before attempting to use the backend API.
 *
 * In case an authentication refresh is required, the passed functions will
 * be queued and called when the refresh process completes, and false will
 * be returned. Otherwise, true is returned and no function is called.
 *
 * @param {function} service Service call to be queued
 * @param {function(boolean,object)} failCallback Called in case the refresh process fails
 * @returns {boolean}
 */
function _checkAuth(service, failCallback) {
  return auth.checkBeforeUse(function (result, error) {
    if (result) {
      if (service) {
        return service();
      }
    } else {
      if (failCallback) {
        failCallback(result, error);
      }
    }
  });
}

/**
 * Provides additional headers required for API interaction using axios.
 *
 * Currently, only the JWT authentication tokens need to be included as an HTTP
 * Authorization parameter.
 *
 * @returns {object}
 */
function _getHeaders() {
  return {
    Authorization: auth.accessToken.raw,
  };
}

// calls is a map of type [String] CancelTokenStatic
// it is used to cancel an old HTTP Requests request when a new one comes, to the same endpoint and method (GET, POST, ...)
let calls = {};

/**
 * Closes an websocket and stomp client
 *
 * @param {object} mSock  the websocket object to be closed
 * @param {object} mStompClient  the stomp client object to be closed
 *
 * @return { void }
 */
export function closeWebsocket(mSock, mStompClient) {
  if (mSock) {
    try {
      mSock.close();
    } catch (err) {}
  }
  if (mStompClient) {
    try {
      mStompClient.disconnect(function () {});
    } catch (err) {}
  }
}

/**
 * Creates a new Websocket and Stomp client.
 *
 * @param { function () {} }  handleData is a callback function that process the data sent from the server
 * @param { string }          endpoint  the endpoint for which the stomp client is going to subscribe
 * @param { function (e){} }  onErrorCallback  function called when there is an error to the connection or the stomp client
 *
 * @return {object, object}   in case of sucess, this function returns a websocket object and a stomp client object, respectively, otherwise, it will return null for both
 */
export function createWebsocket(handleData, endpoint, onErrorCallback) {
  try {
    let finalBackendUrl = `${window.location.protocol}//${window.location.hostname}/secureWebsocket`;
    var sock = new SockJS(finalBackendUrl);
    var stompClient = Stomp.over(sock);
    stompClient.debug = (message) => {
      // console.log(message);
    };
    let headers = {
      "X-Authorization": auth.accessToken.raw,
    };
    stompClient.connect(
      headers,
      function (frame) {
        stompClient.subscribe(
          endpoint,
          function (msg) {
            var msgBody = JSON.parse(msg.body);
            handleData(msgBody);
          },
          headers
        );
      },
      (err) => {
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      }
    );
    return [sock, stompClient];
  } catch (err) {
    return [null, null];
  }
}

/**
 * Singleton that provides all the backend API calls as methods.
 */
export var services = {
  putProjectToStatus: function (body, projectID, statusID, callback) {
    if (
      !_checkAuth(
        () => services.putProjectToStatus(body, projectID, statusID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID + "/status/" + statusID;
    axios
      .put(url, body, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateMasterRecurringEntry: function (projectID, recurringEntryID, callback) {
    if (
      !_checkAuth(
        () =>
          services.updateMasterRecurringEntry(
            projectID,
            recurringEntryID,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID + "/" + recurringEntryID + "/mre";
    axios
      .post(
        url,
        {},
        {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  postOnlineUsers: function (callback) {
    if (!_checkAuth(() => services.postOnlineUsers(callback), callback)) {
      return;
    }

    let url = "api/v1/onlineUsers";

    axios
      .post(
        url,
        {},
        {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },

  getUsersActivity: function (callback) {
    if (!_checkAuth(() => services.getUsersActivity(callback), callback)) {
      return;
    }

    axios
      .all([
        axios.get("api/v1/onlineUsers", {
          headers: _getHeaders(),
        }),
        axios.get("api/v1/activeUsers", {
          headers: _getHeaders(),
        }),
      ])
      .then(function (response) {
        callback(true, {
          onlineUsers: response[0].data,
          activeUsers: response[1].data,
        });
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchEmployeeFiscalYear: function (id, data, callback) {
    if (
      !_checkAuth(
        () => services.patchEmployeeFiscalYear(id, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/employeeFiscalYear/" + id;

    axios
      .patch(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteMonthlyProfitLossByID: function (id, callback) {
    if (
      !_checkAuth(
        () => services.deleteMonthlyProfitLossByID(id, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss/" + id;

    axios
      .delete(url, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchMonthlyProfitLoss: function (id, data, callback) {
    if (
      !_checkAuth(
        () => services.patchMonthlyProfitLoss(id, data, callback),
        callback
      )
    ) {
      return;
    }
    let url = "api/v1/monthlyProfitLoss/" + id;

    axios
      .patch(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateMonthlyProfitLoss: function (id, data, callback) {
    if (
      !_checkAuth(
        () => services.updateMonthlyProfitLoss(id, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss/" + id;

    axios
      .put(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchMonthlyProfitLossWithParams: function (params, data, callback) {
    if (
      !_checkAuth(
        () => services.patchMonthlyProfitLossWithParams(params, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss";

    axios
      .patch(url, data, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  createMonthlyProfitLoss: function (data, callback) {
    if (
      !_checkAuth(
        () => services.createMonthlyProfitLoss(data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss";

    axios
      .post(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getMonthlyProfitLossByID: function (elementID, callback) {
    if (
      !_checkAuth(
        () => services.getMonthlyProfitLossByID(elementID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss/" + elementID;

    axios
      .get(url, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getMonthlyProfitLoss: function (params, headers, callback) {
    if (
      !_checkAuth(
        () => services.getMonthlyProfitLoss(params, headers, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/monthlyProfitLoss";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        params: params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  putAdvancedSearchFilter: function (employeeID, data, callback) {
    if (
      !_checkAuth(
        () => services.putAdvancedSearchFilter(employeeID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/employees/" + employeeID + "/advancedSearch";
    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deleteEmployeeAdvancedSearch: function (
    employeeID,
    advancedSearchFilterID,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.deleteEmployeeAdvancedSearch(
            employeeID,
            advancedSearchFilterID,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "api/v1/employees/" +
      employeeID +
      "/advancedSearch/" +
      advancedSearchFilterID;
    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getEmployeeAdvancedSearch: function (employeeID, callback) {
    if (
      !_checkAuth(
        () => services.getEmployeeAdvancedSearch(employeeID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/employees/" + employeeID + "/advancedSearch";
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  putFiscalYears: function (fiscalYearID, data, callback) {
    if (
      !_checkAuth(
        () => services.putVendors(fiscalYearID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/fiscalYears/" + fiscalYearID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  postFiscalYears: function (data, callback) {
    if (!_checkAuth(() => services.postFiscalYears(data, callback), callback)) {
      return;
    }

    let url = "api/v1/fiscalYears";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getFiscalYear: function (fiscalYearID, callback) {
    if (
      !_checkAuth(
        () => services.getFiscalYear(fiscalYearID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/fiscalYears/" + fiscalYearID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteFiscalYear: function (fiscalYearID, callback) {
    if (
      !_checkAuth(
        () => services.deleteFiscalYear(fiscalYearID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/fiscalYears/" + fiscalYearID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  cloneProject: function (projectID, callback) {
    if (
      !_checkAuth(() => services.cloneProject(projectID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID + "/clone";

    axios
      .post(
        url,
        {},
        {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createChild: function (projectID, callback) {
    if (
      !_checkAuth(() => services.createChild(projectID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID + "/child";

    axios
      .post(
        url,
        {},
        {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createMreProject: function (projectID, callback) {
    if (
      !_checkAuth(
        () => services.createMreProject(projectID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID + "/mre";

    axios
      .post(
        url,
        {},
        {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getEngineerProfitability: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getEngineerProfitability(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/reporting/engineerProfitability";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getAccountManagerProfitability: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getAccountManagerProfitability(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/reporting/accountManagerProfitability";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCustomerRatingAndPriority: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getCustomerRatingAndPriority(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/reporting/customerRatingAndPriority";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNextCustomerNumber: function (callback) {
    if (!_checkAuth(() => services.getNextCustomerNumber(callback), callback)) {
      return;
    }

    let url = "/api/v1/clients/nextCustomerNumber";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getAvailableProjectNumber: function (callback) {
    if (
      !_checkAuth(() => services.getAvailableProjectNumber(callback), callback)
    ) {
      return;
    }
    let availableProjectNumber = localStorage.getItem("availableProjectNumber");
    let params = {};
    if (availableProjectNumber) {
      params["availableProjectNumber"] = availableProjectNumber;
    }

    let url = "/api/v1/projects/availableProjectNumber";

    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        localStorage.setItem(
          "availableProjectNumber",
          response.data["projectNumber"]
        );
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchCommission: function (commissionID, commissionData, params, callback) {
    if (
      !_checkAuth(
        () =>
          services.patchCommission(
            commissionID,
            commissionData,
            params,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/commissions/" + commissionID.toString();

    axios
      .patch(url, commissionData, {
        headers: _getHeaders(),
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deleteTenderLoginByID: function (tenderLoginID, callback) {
    if (
      !_checkAuth(
        () => services.deleteTenderLoginByID(tenderLoginID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/tenderLogins/" + tenderLoginID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  postTenderLogins: function (data, callback) {
    if (!_checkAuth(() => services.getTenderLogins(data, callback), callback)) {
      return;
    }

    let url = "api/v1/tenderLogins";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  putTenderLogins: function (tenderLoginID, data, callback) {
    if (
      !_checkAuth(
        () => services.getTenderLogins(tenderLoginID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/tenderLogins/" + tenderLoginID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getTenderLogins: function (callback) {
    if (!_checkAuth(() => services.getTenderLogins(callback), callback)) {
      return;
    }

    let url = "api/v1/tenderLogins";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getTenderLoginByID: function (id, callback) {
    if (
      !_checkAuth(() => services.getTenderLoginByUuid(id, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/tenderLogins/" + id.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getTenderLoginByUuid: function (uuid, callback) {
    if (
      !_checkAuth(() => services.getTenderLoginByUuid(uuid, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/tenderLogins/uuid/" + uuid;

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  // putSuppliersPromise: function(supplierID, data, callback) {
  //   if (
  //     !_checkAuth(
  //       () => services.putSuppliersPromise(supplierID, data, callback),
  //       callback
  //     )
  //   ) {
  //     return Promise.resolve({});
  //   }

  //   let url = "api/v1/suppliers/" + supplierID.toString();

  //   return axios.put(url, data, {
  //     headers: _getHeaders(),
  //     params: {}
  //   });
  // },
  putSuppliers: function (supplierID, data, callback) {
    if (
      !_checkAuth(
        () => services.putSuppliers(supplierID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/suppliers/" + supplierID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  postSuppliers: function (data, callback) {
    if (!_checkAuth(() => services.postSuppliers(data, callback), callback)) {
      return;
    }

    let url = "api/v1/suppliers";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getSupplier: function (supplierID, callback) {
    if (
      !_checkAuth(() => services.getSupplier(supplierID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/suppliers/" + supplierID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteSupplier: function (supplierID, callback) {
    if (
      !_checkAuth(() => services.deleteSupplier(supplierID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/suppliers/" + supplierID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getSuppliers: function (callback) {
    if (!_checkAuth(() => services.getSuppliers(callback), callback)) {
      return;
    }

    let url = "api/v1/suppliers";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNsaTotals: function (headers, params, responseType, callback) {
    if (
      !_checkAuth(
        () => services.getNsaTotals(headers, params, responseType, callback),
        callback
      )
    ) {
      return;
    }

    axios
      .get("/api/v1/nsa/totals", {
        headers: { ..._getHeaders(), ...headers },
        params: params,
        responseType: responseType,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNsaDashboardLists: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getNsaDashboardLists(params, callback),
        callback
      )
    ) {
      return;
    }

    let employeesParams = {};
    if (params.employeesParams) {
      employeesParams = {
        ...params.employeesParams,
      };
    } else {
      employeesParams["role"] = "account_manager";
      employeesParams["sort"] = "email";
    }
    axios
      .all([
        axios.get("api/v1/fiscalYears", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/admin/employees", {
          headers: _getHeaders(),
          params: employeesParams,
        }),
        axios.get("api/v1/regions", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/projectTypes", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/technologies", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/nsa/totals", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/vendors", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/clients", {
          headers: _getHeaders(),
          params: {"short": 1},
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getUpdateQuoteData: function (quoteID, callback) {
    if (
      !_checkAuth(
        () => services.getCreateQuoteData(quoteID, callback),
        callback
      )
    ) {
      return;
    }

    axios
      .all([
        axios.get("/api/v1/quotes/" + quoteID, {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("/api/v1/internalSystem/lists", {
          headers: _getHeaders(),
          params: {},
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getRenewalsMainPageData: function (callback) {
    if (
      !_checkAuth(() => services.getRenewalsMainPageData(callback), callback)
    ) {
      return;
    }

    axios
      .all([
        axios.get("/api/v1/internalSystem/lists", {
          headers: _getHeaders(),
          params: {},
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  processExcel: function (formdata, callback) {
    if (
      !_checkAuth(
        () => services.processExcel(formdata, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/quotes/processExcelQuote";

    axios
      .post(url, formdata, {
        headers: { ..._getHeaders(), "content-type": "multipart/form-data" },
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCreateQuoteData: function (callback) {
    if (!_checkAuth(() => services.getCreateQuoteData(callback), callback)) {
      return;
    }

    let availableProjectNumber = localStorage.getItem("availableProjectNumber");
    let params = {};
    if (availableProjectNumber) {
      params["availableProjectNumber"] = availableProjectNumber;
    }
    axios
      .all([
        axios.get("/api/v1/projects/availableProjectNumber", {
          headers: _getHeaders(),
          params,
        }),
        axios.get("/api/v1/internalSystem/lists", {
          headers: _getHeaders(),
          params: {},
        }),
      ])
      .then(function (response) {
        localStorage.setItem(
          "availableProjectNumber",
          response[0].data["projectNumber"]
        );
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getExpensesData: function (callback) {
    if (!_checkAuth(() => services.getExpensesData(callback), callback)) {
      return;
    }

    axios
      .all([
        axios.get("/api/v1/fiscalYears", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("/api/v1/expenses", {
          headers: _getHeaders(),
          params: { fiscalYear: "current" },
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  listExpenses: function (params, callback) {
    if (!_checkAuth(() => services.listExpenses(params, callback), callback)) {
      return;
    }

    axios
      .get("/api/v1/expenses", {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getExpensesByRegionId: function (regionId,fyId,callback) {
    if (!_checkAuth(() => services.getExpensesByRegionId(regionId,fyId,callback), callback)) {
      return;
    }
    
    let url = "/api/v1/expenses/";
    if(fyId){
     url= url+ regionId+"/"+fyId;
    } else{
      url=url + regionId
    }
    
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {        
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getExpensesByFyId: function (fyId,callback) {
    if (!_checkAuth(() => services.getExpensesByFyId(fyId,callback), callback)) {
      return;
    }
    let url = "/api/v1/expenses/regions/totals/"+fyId;
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {        
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  postExpenseType: function (data,callback) {
    if (!_checkAuth(() => services.postExpenseType(data,callback), callback)) {
      return;
    }
    let url = "/api/v1/expense/types";
    axios
      .post( url,data, {
          headers: _getHeaders(),
        }
      )
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchQuoteELements: function (data, callback) {
    if (
      !_checkAuth(() => services.patchQuoteELements(data, callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/quoteElements";
    axios
      .patch(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchQuoteELementByID: function (id, data, callback) {
    if (
      !_checkAuth(
        () => services.patchQuoteELementByID(id, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/quoteElements/" + id;
    axios
      .patch(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateExpense: function (expenseID, data, callback) {
    if (
      !_checkAuth(
        () => services.updateExpense(expenseID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/expenses/" + expenseID;
    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateExpenseV2: function (data, callback) {
    if (
      !_checkAuth(
        () => services.updateExpense( data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/expenses/";
    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNonQuotedOpportunitiesLists: function (callback) {
    if (
      !_checkAuth(
        () => services.getNonQuotedOpportunitiesLists(callback),
        callback
      )
    ) {
      return;
    }

    axios
      .all([
        axios.get("api/v1/clients", {
          headers: _getHeaders(),
          params: {},
        }),

        axios.get("api/v1/vendors", {
          headers: _getHeaders(),
          params: {},
        }),
        axios.get("api/v1/technologies", {
          headers: _getHeaders(),
          params: {},
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  putVendors: function (vendorID, data, callback) {
    if (
      !_checkAuth(() => services.putVendors(vendorID, data, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/vendors/" + vendorID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  postVendors: function (data, callback) {
    if (!_checkAuth(() => services.postVendors(data, callback), callback)) {
      return;
    }

    let url = "api/v1/vendors";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getVendor: function (vendorID, callback) {
    if (!_checkAuth(() => services.getVendor(vendorID, callback), callback)) {
      return;
    }

    let url = "api/v1/vendors/" + vendorID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteVendor: function (vendorID, callback) {
    if (
      !_checkAuth(() => services.deleteVendor(vendorID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/vendors/" + vendorID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getVendorsParams: function (params, callback) {
    if (
      !_checkAuth(() => services.getVendorsParams(params, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/vendors";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getVendors: function (callback) {
    if (!_checkAuth(() => services.getVendors(callback), callback)) {
      return;
    }

    let url = "api/v1/vendors";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteCustomerDiscount: function (customerDiscountID, callback) {
    if (
      !_checkAuth(
        () => services.deleteCustomerDiscount(customerDiscountID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/customerDiscounts/" + customerDiscountID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  putCustomerDiscount: function (customerDiscountID, data, callback) {
    if (
      !_checkAuth(
        () => services.putCustomerDiscount(customerDiscountID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/customerDiscounts/" + customerDiscountID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  postCustomerDiscount: function (data, callback) {
    if (
      !_checkAuth(() => services.getCustomerDiscounts(data, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/customerDiscounts";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCustomerDiscount: function (customerDiscountID, callback) {
    if (
      !_checkAuth(
        () => services.getCustomerDiscount(customerDiscountID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/customerDiscounts/" + customerDiscountID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCustomerDiscounts: function (callback) {
    if (!_checkAuth(() => services.getCustomerDiscounts(callback), callback)) {
      return;
    }

    let url = "api/v1/customerDiscounts";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCommissionAchieved: function (employeeID, params, callback) {
    if (
      !_checkAuth(
        () => services.getCommissionAchieved(employeeID, params, callback),
        callback
      )
    ) {
      return;
    }

    axios
      .all([
        axios.get("api/v1/employees/" + employeeID + "/commissionAchieved", {
          headers: _getHeaders(),
          params: { ...params },
        }),
      ])

      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getAccountManagerSummaryRevenue: function (employeeID, params, callback) {
    if (
      !_checkAuth(
        () =>
          services.getAccountManagerSummaryRevenue(
            employeeID,
            params,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/employees/" + employeeID + "/summaryRevenue";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCurrentFiscalYear: function (callback) {
    if (!_checkAuth(() => services.getCurrentFiscalYear(callback), callback)) {
      return;
    }

    let url = "api/v1/fiscalYears/currentFiscalYear";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getFiscalYears: function (callback) {
    if (!_checkAuth(() => services.getFiscalYears(callback), callback)) {
      return;
    }

    let url = "/api/v1/fiscalYears";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getExpensesTypes: function (callback) {
    if (!_checkAuth(() => services.getExpensesTypes(callback), callback)) {
      return;
    }

    let url = "/api/v1/expense/types";
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  patchTechnologyOpportunityHeatmap: function (
    technologyOpportunityHeatmapID,
    data,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.patchTechnologyOpportunityHeatmap(
            technologyOpportunityHeatmapID,
            data,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/technologyOpportunityHeatmap/" +
      technologyOpportunityHeatmapID.toString();

    axios
      .patch(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getTechnologyOpportunityHeatmap: function (
    technologyOpportunityHeatmapID,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.getTechnologyOpportunityHeatmap(
            technologyOpportunityHeatmapID,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/technologyOpportunityHeatmap/" +
      technologyOpportunityHeatmapID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getOpportunitiesPerCustomer: function (employeeID, params, callback) {
    if (
      !_checkAuth(
        () =>
          services.getOpportunitiesPerCustomer(employeeID, params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/employees/" + employeeID + "/opportunitiesPerCustomer";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsCurrentUserOpenQuotes: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectsCurrentUserOpenQuotes(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/currentUser/openQuotes";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getEmployeeProjects: function (
    employeeID,
    projectsType,
    params,
    additionalHeaders,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.getEmployeeProjects(
            employeeID,
            projectsType,
            params,
            additionalHeaders,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/employees/" + employeeID + "/projects/" + projectsType;

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...additionalHeaders },
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteNonQuotedOpportunity: function (nonQuotedOpportunityID, callback) {
    if (
      !_checkAuth(
        () =>
          services.deleteNonQuotedOpportunity(nonQuotedOpportunityID, callback),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/nonQuotedOpportunities/" + nonQuotedOpportunityID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  // deleteNonQuotedOpportunityPromise: function(
  //   nonQuotedOpportunityID,
  //   callback
  // ) {
  //   if (
  //     !_checkAuth(
  //       () =>
  //         services.deleteNonQuotedOpportunityPromise(
  //           nonQuotedOpportunityID,
  //           callback
  //         ),
  //       callback
  //     )
  //   ) {
  //     return;
  //   }

  //   let url =
  //     "/api/v1/nonQuotedOpportunities/" +
  //     nonQuotedOpportunityID.toString();

  //   return axios
  //     .delete(
  //       url,
  //       {
  //         headers: _getHeaders(),
  //         params: {}
  //       }
  //     )
  // },
  patchNonQuotedOpportunity: function (nonQuotedOpportunityID, data, callback) {
    if (
      !_checkAuth(
        () =>
          services.patchNonQuotedOpportunity(
            nonQuotedOpportunityID,
            data,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/nonQuotedOpportunities/" + nonQuotedOpportunityID.toString();

    axios
      .patch(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNonQuotedOpportunity: function (nonQuotedOpportunityID, callback) {
    if (
      !_checkAuth(
        () =>
          services.getNonQuotedOpportunity(nonQuotedOpportunityID, callback),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/nonQuotedOpportunities/" + nonQuotedOpportunityID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateNonQuotedOpportunities: function (
    nonQuotedOpportunityID,
    data,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.updateNonQuotedOpportunities(
            nonQuotedOpportunityID,
            data,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/nonQuotedOpportunities/" + nonQuotedOpportunityID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  createNonQuotedOpportunities: function (data, callback) {
    if (
      !_checkAuth(
        () => services.createNonQuotedOpportunities(data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/nonQuotedOpportunities";

    axios
      .post(url, data, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getAllNonQuotedOpportunities: function (callback) {
    if (
      !_checkAuth(() => services.getNonQuotedOpportunities(callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/nonQuotedOpportunities";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getNonQuotedOpportunities: function (employeeID, callback) {
    if (
      !_checkAuth(
        () => services.getNonQuotedOpportunities(employeeID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/employees/" + employeeID + "/nonQuotedOpportunities";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getRegions: function (callback) {
    if (!_checkAuth(() => services.getRegions(callback), callback)) {
      return;
    }

    let url = "/api/v1/regions";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteClient: function (clientID, callback) {
    if (
      !_checkAuth(() => services.deleteClient(clientID, callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/clients/" + clientID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  patchClient: function (clientID, data, callback) {
    if (
      !_checkAuth(
        () => services.patchClient(clientID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/clients/" + clientID.toString();

    axios
      .patch(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },

  updateClient: function (clientID, data, callback) {
    if (
      !_checkAuth(
        () => services.updateClient(clientID, data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/clients/" + clientID.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createClient: function (data, callback) {
    if (!_checkAuth(() => services.createClient(data, callback), callback)) {
      return;
    }

    let url = "/api/v1/clients";

    axios
      .post(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getClientContacts: function (clientID, callback) {
    if (
      !_checkAuth(
        () => services.getClientContacts(clientID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/clients/" + clientID.toString() + "/contacts";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getClient: function (clientID, callback) {
    if (!_checkAuth(() => services.getClient(clientID, callback), callback)) {
      return;
    }

    let url = "/api/v1/clients/" + clientID.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  // getClientsPromise: function(callback) {
  //   if (!_checkAuth(() => services.getClientsPromise(callback), callback)) {
  //     return;
  //   }

  //   let url = "/api/v1/clients";

  //   return axios
  //     .get(url, {
  //       headers: _getHeaders(),
  //       params: {}
  //     })
  // },
  getClients: function (params, callback) {
    if (!_checkAuth(() => services.getClients(params, callback), callback)) {
      return;
    }

    let url = "/api/v1/clients";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: params ? params : {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },

  // getProjectStatusPromise: function(callback) {
  //   if (
  //     !_checkAuth(() => services.getProjectStatusPromise(callback), callback)
  //   ) {
  //     return Promise.resolve({});
  //   }

  //   let url = "/api/v1/projectStatus";

  //   return axios.get(url, {
  //     headers: _getHeaders(),
  //     params: {}
  //   });
  // },
  getProjectStatus: function (callback) {
    if (!_checkAuth(() => services.getProjectStatus(callback), callback)) {
      return;
    }

    let url = "/api/v1/projectStatus";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  // getProjectVersionHistoryByProjectID: function (projectID, callback) {
  //   if (
  //     !_checkAuth(
  //       () =>
  //         services.getProjectVersionHistoryByProjectID(
  //           projectID,
  //           callback
  //         ),
  //       callback
  //     )
  //   ) {
  //     return;
  //   }

  //   let url = "/api/v1/versionHistory/" + projectID;

  //   axios
  //     .get(url, {
  //       headers: _getHeaders(),
  //       params: {
  //       }
  //     })
  //     .then(function (response) {
  //       callback(true, response.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //       callback(false, error.response);
  //     });
  // },
  getProjectVersionHistory: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectVersionHistory(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/versionHistory";

    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectVersionHistoryDeltaChange: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectVersionHistoryDeltaChange(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projectVersionHistoryDeltaChange";

    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectVersionHistoryDailyStats: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectVersionHistoryDailyStats(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projectVersionHistoryDailyStats";

    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateCommissionStructureById: function (
    data,
    commissionStructureId,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.updateCommissionStructureById(
            data,
            commissionStructureId,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "api/v1/commissionStructuresManagement/commissionStructures/" +
      commissionStructureId.toString();

    axios
      .put(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getCommissionStructureById: function (commissionStructureId, callback) {
    if (
      !_checkAuth(
        () =>
          services.getCommissionStructureById(commissionStructureId, callback),
        callback
      )
    ) {
      return;
    }

    let url =
      "/api/v1/commissionStructuresManagement/commissionStructures/" +
      commissionStructureId.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCommissionStructure: function (callback) {
    if (
      !_checkAuth(() => services.getCommissionStructure(callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/commissionStructuresManagement/commissionStructures";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  deleteEmployee: function (employeeId, callback) {
    if (
      !_checkAuth(() => services.deleteEmployee(employeeId, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/admin/employees/" + employeeId.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },

  patchEmployee: function (employeeData, employeeId, callback) {
    if (
      !_checkAuth(
        () => services.patchEmployee(employeeData, employeeId, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/admin/employees/" + employeeId.toString();

    axios
      .patch(url, employeeData, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  updateEmployee: function (employeeData, employeeId, callback) {
    if (
      !_checkAuth(
        () => services.updateEmployee(employeeData, employeeId, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/admin/employees/" + employeeId.toString();

    axios
      .put(url, employeeData, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getEmployee: function (employeeId, callback) {
    if (
      !_checkAuth(() => services.getEmployee(employeeId, callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/admin/employees/" + employeeId.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  createEmployee: function (employeeData, callback) {
    if (
      !_checkAuth(
        () => services.createEmployee(employeeData, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/admin/employees";

    axios
      .post(url, employeeData, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getRoles: function (callback) {
    if (!_checkAuth(() => services.getRoles(callback), callback)) {
      return;
    }

    let url = "/api/v1/roles";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getCreateEditEmployeeData: function (callback) {
    if (
      !_checkAuth(() => services.getCreateEditEmployeeData(callback), callback)
    ) {
      return;
    }

    axios
      .all([
        axios.get("/api/v1/roles", {
          headers: _getHeaders(),
          params: {},
        }),

        axios.get("api/v1/regions", {
          headers: _getHeaders(),
          params: {},
        }),
      ])
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getEmployees: function (params, callback) {
    if (!_checkAuth(() => services.getEmployees(params, callback), callback)) {
      return;
    }

    let url = "/api/v1/admin/employees";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsTotalsCurrentUser: function (params, headers, callback) {
    if (
      !_checkAuth(
        () => services.getProjectsTotalsCurrentUser(params, headers, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/currentUser/totals";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        params: {
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsTotals: function (params, headers, callback) {
    if (
      !_checkAuth(
        () => services.getProjectsTotals(params, headers, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/totals";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        params: {
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsPageQueryAccMngrPracticeLead: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.getProjectsPageQueryAccMngrPracticeLead(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/currentUser";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {
          page: page,
          size: pageSize,
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsNumber: function (params, callback) {
    if (
      !_checkAuth(() => services.getProjectsNumber(params, callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/projects/projectsNumber";
    let callID = "get:" + url;

    if (calls[callID]) {
      calls[callID].cancel("Request cancelled");
    }
    calls[callID] = axios.CancelToken.source();

    let config = {
      method: "get",
      url: url,
      headers: { ..._getHeaders() },
      params: {
        ...params,
      },
    };
    config.cancelToken = calls[callID].token;
    axios(config)
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getQuotesProjectNumbers: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getQuotesProjectNumbers(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/quotes/projectsNumber";
    let callID = "get:" + url;

    if (calls[callID]) {
      calls[callID].cancel("Request cancelled");
    }
    calls[callID] = axios.CancelToken.source();

    let config = {
      method: "get",
      url: url,
      headers: { ..._getHeaders() },
      params: {
        ...params,
      },
    };
    config.cancelToken = calls[callID].token;
    axios(config)
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsAnnuityRenewals: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectsAnnuityRenewals(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/annuityRenewals";
    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getProjectsPageQuery: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.getProjectsPageQuery(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects";
    let callID = "get:" + url;

    if (calls[callID]) {
      calls[callID].cancel("Request cancelled");
    }
    calls[callID] = axios.CancelToken.source();

    let config = {
      method: "get",
      url: url,
      headers: { ..._getHeaders(), ...headers },
      responseType: responseType,
      params: {
        page: page,
        size: pageSize,
        ...params,
      },
    };
    config.cancelToken = calls[callID].token;
    axios(config)
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });

    // axios
    //   .get(url, {
    //     headers: { ..._getHeaders(), ...headers },
    //     responseType: responseType,
    //     params: {
    //       page: page,
    //       size: pageSize,
    //       ...params
    //     }
    //   })
    //   .then(function (response) {
    //     callback(true, response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //     callback(false, error.response);
    //   });
  },
  getProjectsNegativeGroupedGrossProfit: function (params, callback) {
    if (
      !_checkAuth(
        () => services.getProjectsNegativeGroupedGrossProfit(params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/projects/negativeGroupedGrossProfit";
    axios
      .get(url, {
        headers: _getHeaders(),
        params,
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  updateProjectForecasting: function (
    projectForecastingId,
    projectForecastingObj,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.updateProjectForecasting(
            projectForecastingId,
            projectForecastingObj,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url =
      "api/v1/projects/projectForecasting/" + projectForecastingId.toString();

    axios
      .put(url, projectForecastingObj, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getProjectForecasting: function (projectId, callback) {
    if (
      !_checkAuth(
        () => services.getProjectForecasting(projectId, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/projectForecasting/" + projectId.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  patchProject: function (projectId, projectData, callback) {
    if (
      !_checkAuth(
        () => services.patchProject(projectId, projectData, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectId.toString();

    axios
      .patch(url, projectData, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  // updateProjectRecurrentWon: function (
  //   projectId,
  //   projectData,
  //   params,
  //   callback
  // ) {
  //   if (
  //     !_checkAuth(
  //       () =>
  //         services.updateProjectRecurrentWon(
  //           projectId,
  //           projectData,
  //           params,
  //           callback
  //         ),
  //       callback
  //     )
  //   ) {
  //     return;
  //   }

  //   let url = "api/v1/projects/" + projectId.toString() + "/wonStage";

  //   axios
  //     .put(url, projectData, {
  //       headers: _getHeaders(),
  //       params: { ...params }
  //     })
  //     .then(function (response) {
  //       callback(true, response.data);
  //     })
  //     .catch(function (error) {
  //       callback(false, error.response);
  //     });
  // },
  updateProject: function (projectId, projectData, params, callback) {
    if (
      !_checkAuth(
        () => services.updateProject(projectId, projectData, params, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectId.toString();

    axios
      .put(url, projectData, {
        headers: _getHeaders(),
        params: { ...params },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getProject: function (projectId, callback) {
    if (!_checkAuth(() => services.getProject(projectId, callback), callback)) {
      return;
    }

    let url = "api/v1/projects/" + projectId.toString();

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deleteProject: function (projectID, callback) {
    if (
      !_checkAuth(() => services.deleteProject(projectID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/projects/" + projectID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deleteQuoteByID: function (quoteID, callback) {
    if (
      !_checkAuth(() => services.deleteQuoteByID(quoteID, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/quotes/" + quoteID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getQuoteByID: function (quoteID, headers, responseType, callback) {
    if (
      !_checkAuth(
        () => services.getQuoteByID(quoteID, headers, responseType, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/quotes/" + quoteID.toString();

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {},
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getProjectByQuoteID: function (quoteID, callback) {
    if (
      !_checkAuth(
        () => services.getProjectByQuoteID(quoteID, callback),
        callback
      )
    ) {
      return;
    }

    let url = `api/v1/quotes/${quoteID.toString()}/project`;

    axios
      .get(url, {
        headers: { ..._getHeaders() },
        params: {},
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  listQuotes: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.listQuotes(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/quotes";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {
          page: page,
          size: pageSize,
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateQuoteByID: function (quoteID, data, headers, callback) {
    if (
      !_checkAuth(
        () => services.updateQuoteByID(quoteID, data, headers, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/quotes/" + quoteID;

    axios
      .put(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType: "arraybuffer",
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createQuote: function (data, headers, callback) {
    if (
      !_checkAuth(() => services.createQuote(data, headers, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/quotes";

    axios
      .post(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType: "arraybuffer",
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  getInvoiceNumbers: function (params, callback) {
    if (
      !_checkAuth(() => services.getInvoiceNumbers(params, callback), callback)
    ) {
      return;
    }

    let url = "/api/v1/invoices/invoicesNumbers";
    let callID = "get:" + url;

    if (calls[callID]) {
      calls[callID].cancel("Request cancelled");
    }
    calls[callID] = axios.CancelToken.source();

    let config = {
      method: "get",
      url: url,
      headers: { ..._getHeaders() },
      params: {
        ...params,
      },
    };
    config.cancelToken = calls[callID].token;
    axios(config)
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getInvoiceByID: function (quoteID, headers, responseType, callback) {
    if (
      !_checkAuth(
        () => services.getInvoiceByID(quoteID, headers, responseType, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/invoices/" + quoteID.toString();

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {},
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deleteInvoiceByID: function (invoiceID, callback) {
    if (
      !_checkAuth(
        () => services.deleteInvoiceByID(invoiceID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/invoices/" + invoiceID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  listInvoices: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.listInvoices(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/invoices";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {
          page: page,
          size: pageSize,
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updateInvoiceByID: function (
    invoiceID,
    data,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.updateInvoiceByID(
            invoiceID,
            data,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/invoices/" + invoiceID;

    axios
      .put(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType,
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createInvoice: function (data, headers, responseType, callback) {
    if (
      !_checkAuth(
        () => services.createInvoice(data, headers, responseType, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/invoices";

    axios
      .post(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType,
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },

  // Purchase Orders
  getPurchaseOrderByID: function (ID, headers, responseType, callback) {
    if (
      !_checkAuth(
        () =>
          services.getPurchaseOrderByID(ID, headers, responseType, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/purchaseorders/" + ID.toString();

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {},
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  deletePurchaseOrderByID: function (ID, callback) {
    if (
      !_checkAuth(
        () => services.deletePurchaseOrderByID(ID, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/purchaseorders/" + ID.toString();

    axios
      .delete(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  listPurchaseOrders: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.listPurchaseOrders(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/purchaseorders";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {
          page: page,
          size: pageSize,
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  updatePurchaseOrderByID: function (
    ID,
    data,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.updatePurchaseOrderByID(
            ID,
            data,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/purchaseorders/" + ID;

    axios
      .put(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType,
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createPurchaseOrder: function (data, headers, responseType, callback) {
    if (
      !_checkAuth(
        () =>
          services.createPurchaseOrder(data, headers, responseType, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/purchaseorders";

    axios
      .post(url, data, {
        headers: { ..._getHeaders(), ...headers },
        responseType,
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },

  verifyQuoteRefs: function (data, callback) {
    if (
      !_checkAuth(
        () =>
          services.verifyQuoteRefs(data, callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/purchaseorders/quoteRefs";

    axios
      .post(url, data, {
        headers: { ..._getHeaders()},
      })
      .then(function (response) {
        callback(true, response);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  // --------- end Purchase Orders -------------------

  listRenewals: function (
    page,
    pageSize,
    params,
    headers,
    responseType,
    callback
  ) {
    if (
      !_checkAuth(
        () =>
          services.listRenewals(
            page,
            pageSize,
            params,
            headers,
            responseType,
            callback
          ),
        callback
      )
    ) {
      return;
    }

    let url = "/api/v1/renewals";

    axios
      .get(url, {
        headers: { ..._getHeaders(), ...headers },
        responseType: responseType,
        params: {
          page: page,
          size: pageSize,
          ...params,
        },
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  createProject: function (projectData, callback) {
    if (
      !_checkAuth(() => services.createProject(projectData, callback), callback)
    ) {
      return;
    }

    let url = "api/v1/projects";

    axios
      .post(url, projectData, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },

  getSystemLists: function (callback) {
    if (!_checkAuth(() => services.getSystemLists(callback), callback)) {
      return;
    }

    let url = "/api/v1/internalSystem/lists";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        callback(false, error.response);
      });
  },
  createExpenses: function (data,fromModal, callback) {
    if (
      !_checkAuth(
        () => services.createExpenses(data,fromModal,callback),
        callback
      )
    ) {
      return;
    }

    let url = "api/v1/expenses/"+fromModal;

    axios
      .post(url, data, {
        headers: _getHeaders(),
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getExpensesTotals: function (regionId,fyId,callback) {
    if (!_checkAuth(() => services.getExpensesTotals(regionId,fyId,callback), callback)) {
      return;
    }
    if(null===fyId){
      return;
    }
    let url = "/api/v1/expense/totals/"+ regionId+"/"+fyId;
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getFiscalYearExpensesTotals: function (fyId,callback) {
    if (!_checkAuth(() => services.getExpensesTotals(fyId,callback), callback)) {
      return;
    }
    if(null===fyId){
      return;
    }
    let url = "/api/v1/expenses/totals/"+fyId;
    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  getUserLog: function (callback) {
    if (!_checkAuth(() => services.getUserLog(callback), callback)) {
      return;
    }

    let url = "api/v1/userLog";

    axios
      .get(url, {
        headers: _getHeaders(),
        params: {},
      })
      .then(function (response) {
        callback(true, response.data);
      })
      .catch(function (error) {
        console.error(error);
        callback(false, error.response);
      });
  },
  
};

export default services;
