export const BASE_COLOUR = "#40a0ec"; // logo colour

export const RECURRENCE_TYPE_MONTHLY = "monthly";
export const RECURRENCE_TYPE_QUARTERLY = "quarterly";
export const RECURRENCE_TYPE_ANNUALLY = "annually";

// ---------------------- Error identifiers ----------------------------- //

export const NO_ERROR = -1;
export const ERROR_LOADING = 0;

// ---------------------- Secret and keys ------------------------------- //

export const GOOGLE_SOCIAL_LOGIN_API_KEY = "369420613236-aqrdn73anu8i8t4ieilggbh35f935mid.apps.googleusercontent.com";

export const MICROSOFT_SOCIAL_LOGIN_CLIENDT_ID = "19591b91-6f82-417c-ad87-58a9e63a2c11";
export const MICROSOFT_SOCIAL_LOGIN_TENANT_URL = "https://login.microsoftonline.com/outcomex.com.au";

export const DEFAULT_CURRENCY = "AUD";

export const DEFAULT_GST = 10;

export const RECCURENCE_TYPE_ERROR_MSG =
  'Please select a recurrence type or change the option "IS THE PROJECT RECURRENT" to "NO"';

export const DEFAULT_DATE_FORMAT = "dd/MM/YYYY";

export const DEFAULT_ROW_EVEN = "#3a4149";
export const DEFAULT_ROW_ODD = "#565b60";

export const DEFAULT_ROW_RED = "#ff0000";
export const DEFAULT_ROW_LIGTH_BLUE = "#add8e6";
export const DEFAULT_ROW_DARK_BLUE = "#30819c";
export const DEFAULT_ROW_GREEN = "#9c0";
export const DEFAULT_ROW_YELLOW = "#ff0";
export const DEFAULT_ROW_PINK = "#e83e8c";
export const DEFAULT_SELECTED_ROW = "#00c3ff";
export const DEFAULT_SELECTED_ROW_BORDER = "0.1em solid black";

export const DEFAULT_ROW_QUOTED_90DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_INVOICE_NOW = DEFAULT_ROW_RED;
export const DEFAULT_ROW_INVOICE_35DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_INVOICE_DELIVERED_45DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_INVOICE_ENG_COMPL_60DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_AWAITING_CRED_45DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_WON = DEFAULT_ROW_LIGTH_BLUE;
export const DEFAULT_ROW_INVOICED = DEFAULT_ROW_LIGTH_BLUE;
export const DEFAULT_ROW_INVOICE_ENG_COMPL = DEFAULT_ROW_LIGTH_BLUE;
export const DEFAULT_ROW_WON_3MONTHS = DEFAULT_ROW_DARK_BLUE;
export const DEFAULT_ROW_LOST = "#808080";
export const DEFAULT_ROW_RECEIVED = DEFAULT_ROW_GREEN;
export const DEFAULT_ROW_OUTSTANDING_PAY = DEFAULT_ROW_YELLOW;
export const DEFAULT_ROW_AWAITING_CREDIT = DEFAULT_ROW_PINK;
export const DEFAULT_ROW_RMA = DEFAULT_ROW_PINK;
export const DEFAULT_ROW_INVOICE_LATER_45DAYS = DEFAULT_ROW_RED;
export const DEFAULT_ROW_INVOICE_STATUS_60_DAYS_HOLD = "#ff9a3b";

export const INVOICE_STATUS_INVOICED = 1;
export const INVOICE_STATUS_INVOICE_NOW = 2;
export const INVOICE_STATUS_INVOICE_ENG_COMPLETE = 3;
export const INVOICE_STATUS_INVOICE_DELIVERED = 4;
export const INVOICE_STATUS_AWAITING_CREDIT = 5;
export const INVOICE_STATUS_RMA = 6;
// export const INVOICE_STATUS_TELSTRA_PROCESSING = 7;
export const INVOICE_STATUS_DICKER_PROCESSING = 8;
export const INVOICE_STATUS_VENDOR_PROCESSING = 9;
export const INVOICE_STATUS_NA = 10;
export const INVOICE_STATUS_INVOICE_RECURRING = 11;
export const INVOICE_STATUS_INVOICE_LATER = 12;

export const INVOICE_STATUS_INVOICED_TELSTRA_PP = 13;
export const INVOICE_STATUS_INVOICED_TELSTRA_TBS = 14;
export const INVOICE_STATUS_INVOICED_TELSTRA_MDN = 15;
export const INVOICE_STATUS_INVOICED_TELSTRA_GBL = 16;
export const INVOICE_STATUS_INVOICED_VENDOR_MKG = 17;
export const INVOICE_STATUS_INVOICED_VENDOR_RBTS = 18;

export const INVOICE_STATUS_60_DAYS_HOLD = 19;
export const INVOICE_STATUS_90_DAYS_HOLD = 20;
export const INVOICE_STATUS_CHECK_WITH_PM=21;

export const INVOICES_OVERDUE_35 = 100;
export const INVOICES_OVERDUE_60 = 101;
export const INVOICES_OVERDUE_90 = 102;
export const INVOICES_OVERDUE_120 = 103;
export const INVOICES_OVERDUE_150 = 104;
