import React, { Component } from "react";

class CustomProjectStatusSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      getOptions: this.props.getOptions
    };
  }

  componentDidMount() {
    if (this.state.getOptions) {
      this.state.getOptions((result, data) => {
        if (result) {
          if (this.unmoutend) return;
          let tmpData = data.map(e => {
            return {
              value: e.id,
              label: e.projectStatusAlias ? e.projectStatusAlias : e.projectStatus
            };
          });
          this.setState({ options: tmpData });
        } else {
          console.error(data);
        }
      });
    }
  }

  componentWillUnmount() {
    this.unmoutend = true;
  }

  render() {
    return (
      <select
        className="invoice-status-filter"
        onChange={e => {
          this.props.onChange(e);
        }}>
        <option value={null}>{""}</option>
        {this.state.options.map(function(element, index) {
          let tmp = element.label.toLowerCase();
          tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1);

          return (
            <option className="custom-option" key={index} value={element.value}>
              {tmp}
            </option>
          );
        })}
      </select>
    );
  }
}

export default CustomProjectStatusSelect;
